<template>
<div v-if="$_userMixins_isSuperAdmin">
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="row">
            <div class="col-12">
                <div class="kt-portlet">
                    <div class="kt-portlet__head">
                        <div class="kt-portlet__head-label">
                            <span class="kt-portlet__head-icon">
                                <SVGIcon
                                    name="AWS"
                                    style="width: 24px; height: 24px;"
                                />
                            </span>
                            <h3 class="kt-portlet__head-title">
                                AWS Management
                            </h3>
                        </div>
                        <div class="kt-portlet__head-toolbar">
                            <div class="kt-portlet__head-actions">
                                <button
                                    class="btn btn-outline-success btn-sm btn-icon btn-icon-md"
                                    @click.stop.prevent="getAWSData()"
                                >
                                    <i class="flaticon2-refresh" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__body pt-2">
                        <PortletLoader v-if="loading" />
                        <template v-else>
                            <div v-if="clusters.length > 0">
                                <div
                                    v-for="(cluster, idx) in clusters"
                                    :key="cluster.DBClusterArn"
                                >
                                    <div
                                        id="accordionExample7"
                                        class="accordion accordion-light  accordion-svg-icon"
                                    >
                                        <div class="card">
                                            <div
                                                :id="`heading${idx.toString()}7`"
                                                class="card-header"
                                            >
                                                <div
                                                    class="card-title collapsed"
                                                    data-toggle="collapse"
                                                    :data-target="`#collapse${idx.toString()}7`"
                                                    aria-expanded="false"
                                                    :aria-controls="`collapse${idx.toString()}7`"
                                                >
                                                    <span
                                                        class="kt-badge kt-badge--inline mr-2"
                                                        :class="{
                                                            'kt-badge--success': cluster.Status === 'available',
                                                            'kt-badge--warning': cluster.Status !== 'available' && cluster.Status !== 'stopped',
                                                            'kt-badge--danger': cluster.Status === 'stopped',
                                                        }"
                                                    >
                                                        {{ cluster.TagList.find(tag => tag.Key == 'clusterType').Value }}
                                                    </span>
                                                    {{ cluster.TagList.find(tag => tag.Key == 'clusterName').Value }}
                                                    <span
                                                        class="btn btn-sm btn-pill text-capitalize ml-4"
                                                        :class="{
                                                            'btn-label-success': cluster.Status === 'available',
                                                            'btn-label-warning kt-spinner kt-spinner--v2 kt-spinner--md kt-spinner--warning': cluster.Status !== 'available' && cluster.Status !== 'stopped',
                                                            'btn-label-danger': cluster.Status === 'stopped',
                                                        }"
                                                    >
                                                        {{ cluster.Status }}
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                :id="`collapse${idx.toString()}7`"
                                                class="collapse"
                                                :aria-labelledby="`heading${idx.toString()}7`"
                                                data-parent="#accordionExample7"
                                                style=""
                                            >
                                                <div class="card-body">
                                                    <div class="row mb-3">
                                                        <div class="form-group col-lg-10">
                                                            <div class="row">
                                                                <h5 class="mr-2">
                                                                    {{ cluster.DBClusterIdentifier }}
                                                                </h5>
                                                                <span class="text-muted font-italic">
                                                                    {{ `${cluster.Engine} ${cluster.EngineVersion}` }}
                                                                </span>
                                                            </div>
                                                            <span
                                                                v-for="(zone, index) in cluster.AvailabilityZones"
                                                                :key="`zone_${index}`"
                                                                class="kt-badge kt-badge--brand kt-badge--inline mr-2"
                                                            >
                                                                {{ zone }}
                                                            </span>
                                                        </div>
                                                        <div v-if="cluster.TagList.find(tag => tag.Key == 'clusterType').Value == 'Dev'">
                                                            <button
                                                                v-if="cluster.Status === 'available'"
                                                                class="btn btn-danger btn-bold"
                                                                @click.stop.prevent="confirmStop(cluster.DBClusterIdentifier)"
                                                            >
                                                                Stop Database
                                                            </button>
                                                            <button
                                                                v-else-if="cluster.Status === 'stopped'"
                                                                class="btn btn-success btn-bold"
                                                                @click.stop.prevent="startCluster(cluster.DBClusterIdentifier)"
                                                            >
                                                                Start Database
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="cluster.Status === 'available'"
                                                        class="pb-4"
                                                    >
                                                        <h4 class="kt-font-info">
                                                            {{ cluster.CurrentCapacity }}
                                                        </h4>
                                                        <span class="font-italic">Current ACU Capacity</span>
                                                        <span class="kt-font-bold font-italic ml-2">*May be higher than set minimum capacity from auto-scaling</span>
                                                    </div>
                                                    <div
                                                        v-if="cluster.ServerlessV2ScalingConfiguration && cluster.Status === 'available'"
                                                        class="form-group row"
                                                    >
                                                        <div class="ACUSlider">
                                                            <v-slider
                                                                v-model="cluster.ServerlessV2ScalingConfiguration.MinCapacity"
                                                                :min="0.5"
                                                                :max="128"
                                                                :interval="0.5"
                                                                :tooltip-formatter="val => `${val} ACUs`"
                                                            />
                                                        </div>
                                                        <div class="col-lg-3 align-self-end">
                                                            <button
                                                                type="button"
                                                                class="btn btn-label-brand btn-bold"
                                                                :disabled="!cluster.ServerlessV2ScalingConfiguration.MinCapacity || !cluster.ServerlessV2ScalingConfiguration.MaxCapacity"
                                                                @click="setCapacity(cluster.DBClusterIdentifier)"
                                                            >
                                                                Set Minimum Capacity
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal
        ref="confirm-modal"
        size="lg"
        variant="primary"
        centered
    >
        <template #modal-header="{ close }">
            <h5
                class="modal-title"
            >
                Confirm
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close()"
            />
        </template>
        <template #default>
            <div class="modal-body">
                <h5><span class="kt-font-danger">Temporarily stop the <span class="kt-font-info">{{ selectedIdentifier }}</span> database</span></h5>
            </div>
        </template>
        <template #modal-footer>
            <button
                type="button"
                class="btn btn-danger btn-bold alignment pull-right"
                data-dismiss="modal"
                @click.stop.prevent="stopCluster(selectedIdentifier)"
            >
                Stop Database
            </button>
        </template>
    </b-modal>
</div>
</template>

<script>
import * as network from '../../network';
import PortletLoader from '../../components/PortletLoader.vue';
import userMixins from '../../store/mixins/userMixins';

export default {
    name: 'ManageAWS',
    components: {
        PortletLoader,
    },
    mixins: [userMixins],
    data() {
        return {
            loading: false,
            clusters: [],
            selectedIdentifier: null,
        };
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            const v = this;
            v.getAWSData();
        },
        redirect() {
            window.location.href = '/auth/google/redirect';
        },
        getAWSData() {
            const v = this;
            v.loading = true;
            network.awsAdmin.getRDSData({}, (err, res) => {
                v.loading = false;
                if (err) return v.showError(err);
                v.clusters = res.DBClusters;
            });
        },
        setCapacity(identifier) {
            const v = this;
            v.loading = true;
            const minCapacity = v.clusters.find((c) => c.DBClusterIdentifier === identifier).ServerlessV2ScalingConfiguration.MinCapacity;
            if (!minCapacity) {
                v.loading = false;
                return v.showError('Undefined capacity value');
            }
            if (minCapacity > 128 || minCapacity < 0.5) {
                v.loading = false;
                return v.showError('Please enter capacity values between 0.5 and 128');
            }
            const request = {
                body: {
                    identifier,
                    minCapacity,
                },
            };
            network.awsAdmin.setRDSCapacity(request, (err) => {
                v.loading = false;
                v.getAWSData();
                if (err) return v.showError(err);
                return v.showNotification('This may take up to a minute, refresh the page to check status');
            });
        },
        startCluster(identifier) {
            const v = this;
            v.loading = true;
            const request = {
                body: {
                    identifier,
                },
            };
            network.awsAdmin.startRDSCluster(request, (err) => {
                v.loading = false;
                if (err) return v.showError(err);
                v.getAWSData();
                return v.showNotification('This may take a few minutes, refresh the page to check status');
            });
        },
        stopCluster(identifier) {
            const v = this;
            if (identifier !== 'syncgrades-dev-aurora-serverless') return v.showError('Only Dev can be stopped');
            v.loading = true;
            const request = {
                body: {
                    identifier,
                },
            };
            network.awsAdmin.stopRDSCluster(request, (err) => {
                v.loading = false;
                if (err) return v.showError(err);
                v.$refs['confirm-modal'].close();
                v.getAWSData();
                return v.showNotification('This may take a few minutes, refresh the page to check status');
            });
        },
        confirmStop(identifier) {
            const v = this;
            v.selectedIdentifier = identifier;
            v.$refs['confirm-modal'].show();
        },
    },
};
</script>

<style scoped>
.ACUSlider {
  width: 60%;
  display: block;
  align-self: center;
  margin-right: 2%;
}
</style>
