var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$_userMixins_isSuperAdmin
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass:
                "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "kt-portlet" }, [
                    _c("div", { staticClass: "kt-portlet__head" }, [
                      _c("div", { staticClass: "kt-portlet__head-label" }, [
                        _c(
                          "span",
                          { staticClass: "kt-portlet__head-icon" },
                          [
                            _c("SVGIcon", {
                              staticStyle: { width: "24px", height: "24px" },
                              attrs: { name: "AWS" },
                            }),
                          ],
                          1
                        ),
                        _c("h3", { staticClass: "kt-portlet__head-title" }, [
                          _vm._v(" AWS Management "),
                        ]),
                      ]),
                      _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                        _c("div", { staticClass: "kt-portlet__head-actions" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-outline-success btn-sm btn-icon btn-icon-md",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.getAWSData()
                                },
                              },
                            },
                            [_c("i", { staticClass: "flaticon2-refresh" })]
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "kt-portlet__body pt-2" },
                      [
                        _vm.loading
                          ? _c("PortletLoader")
                          : [
                              _vm.clusters.length > 0
                                ? _c(
                                    "div",
                                    _vm._l(
                                      _vm.clusters,
                                      function (cluster, idx) {
                                        return _c(
                                          "div",
                                          { key: cluster.DBClusterArn },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "accordion accordion-light accordion-svg-icon",
                                                attrs: {
                                                  id: "accordionExample7",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card-header",
                                                        attrs: {
                                                          id: `heading${idx.toString()}7`,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "card-title collapsed",
                                                            attrs: {
                                                              "data-toggle":
                                                                "collapse",
                                                              "data-target": `#collapse${idx.toString()}7`,
                                                              "aria-expanded":
                                                                "false",
                                                              "aria-controls": `collapse${idx.toString()}7`,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "kt-badge kt-badge--inline mr-2",
                                                                class: {
                                                                  "kt-badge--success":
                                                                    cluster.Status ===
                                                                    "available",
                                                                  "kt-badge--warning":
                                                                    cluster.Status !==
                                                                      "available" &&
                                                                    cluster.Status !==
                                                                      "stopped",
                                                                  "kt-badge--danger":
                                                                    cluster.Status ===
                                                                    "stopped",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      cluster.TagList.find(
                                                                        (tag) =>
                                                                          tag.Key ==
                                                                          "clusterType"
                                                                      ).Value
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  cluster.TagList.find(
                                                                    (tag) =>
                                                                      tag.Key ==
                                                                      "clusterName"
                                                                  ).Value
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "btn btn-sm btn-pill text-capitalize ml-4",
                                                                class: {
                                                                  "btn-label-success":
                                                                    cluster.Status ===
                                                                    "available",
                                                                  "btn-label-warning kt-spinner kt-spinner--v2 kt-spinner--md kt-spinner--warning":
                                                                    cluster.Status !==
                                                                      "available" &&
                                                                    cluster.Status !==
                                                                      "stopped",
                                                                  "btn-label-danger":
                                                                    cluster.Status ===
                                                                    "stopped",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      cluster.Status
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "collapse",
                                                        attrs: {
                                                          id: `collapse${idx.toString()}7`,
                                                          "aria-labelledby": `heading${idx.toString()}7`,
                                                          "data-parent":
                                                            "#accordionExample7",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "card-body",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row mb-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-lg-10",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "h5",
                                                                          {
                                                                            staticClass:
                                                                              "mr-2",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  cluster.DBClusterIdentifier
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "text-muted font-italic",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  `${cluster.Engine} ${cluster.EngineVersion}`
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._l(
                                                                      cluster.AvailabilityZones,
                                                                      function (
                                                                        zone,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "span",
                                                                          {
                                                                            key: `zone_${index}`,
                                                                            staticClass:
                                                                              "kt-badge kt-badge--brand kt-badge--inline mr-2",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  zone
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                                cluster.TagList.find(
                                                                  (tag) =>
                                                                    tag.Key ==
                                                                    "clusterType"
                                                                ).Value == "Dev"
                                                                  ? _c("div", [
                                                                      cluster.Status ===
                                                                      "available"
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-danger btn-bold",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    $event.preventDefault()
                                                                                    return _vm.confirmStop(
                                                                                      cluster.DBClusterIdentifier
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Stop Database "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : cluster.Status ===
                                                                          "stopped"
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-success btn-bold",
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    $event.preventDefault()
                                                                                    return _vm.startCluster(
                                                                                      cluster.DBClusterIdentifier
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Start Database "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ])
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                            cluster.Status ===
                                                            "available"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "pb-4",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h4",
                                                                      {
                                                                        staticClass:
                                                                          "kt-font-info",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              cluster.CurrentCapacity
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "font-italic",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Current ACU Capacity"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "kt-font-bold font-italic ml-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "*May be higher than set minimum capacity from auto-scaling"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            cluster.ServerlessV2ScalingConfiguration &&
                                                            cluster.Status ===
                                                              "available"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group row",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ACUSlider",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-slider",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                min: 0.5,
                                                                                max: 128,
                                                                                interval: 0.5,
                                                                                "tooltip-formatter":
                                                                                  (
                                                                                    val
                                                                                  ) =>
                                                                                    `${val} ACUs`,
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  cluster
                                                                                    .ServerlessV2ScalingConfiguration
                                                                                    .MinCapacity,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      cluster.ServerlessV2ScalingConfiguration,
                                                                                      "MinCapacity",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "cluster.ServerlessV2ScalingConfiguration.MinCapacity",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-lg-3 align-self-end",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "btn btn-label-brand btn-bold",
                                                                            attrs:
                                                                              {
                                                                                type: "button",
                                                                                disabled:
                                                                                  !cluster
                                                                                    .ServerlessV2ScalingConfiguration
                                                                                    .MinCapacity ||
                                                                                  !cluster
                                                                                    .ServerlessV2ScalingConfiguration
                                                                                    .MaxCapacity,
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.setCapacity(
                                                                                    cluster.DBClusterIdentifier
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Set Minimum Capacity "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _c("b-modal", {
            ref: "confirm-modal",
            attrs: { size: "lg", variant: "primary", centered: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-header",
                  fn: function ({ close }) {
                    return [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(" Confirm "),
                      ]),
                      _c("button", {
                        staticClass: "close",
                        attrs: {
                          type: "button",
                          "data-dismiss": "modal",
                          "aria-label": "Close",
                        },
                        on: {
                          click: function ($event) {
                            return close()
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "modal-body" }, [
                        _c("h5", [
                          _c("span", { staticClass: "kt-font-danger" }, [
                            _vm._v("Temporarily stop the "),
                            _c("span", { staticClass: "kt-font-info" }, [
                              _vm._v(_vm._s(_vm.selectedIdentifier)),
                            ]),
                            _vm._v(" database"),
                          ]),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "modal-footer",
                  fn: function () {
                    return [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-danger btn-bold alignment pull-right",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.stopCluster(_vm.selectedIdentifier)
                            },
                          },
                        },
                        [_vm._v(" Stop Database ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3198312623
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }